import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import { IErrorField, IFormErrors, IFormErrorsState, StatusCampos } from '@/types/store/form-errors';

const state: IFormErrorsState = {
  formsErrors: {
    dadosCpr: [],
    dadosFinanceiros: [],
    formacoesLavoura: [],
    condicaoEntrega: [],
  },
  camposPendentesRevisao: StatusCampos.NAO_SE_APLICA
};

const getters: GetterTree<IFormErrorsState, RootState> = {
  getFormErrors: (state: IFormErrorsState) => state.formsErrors,
  camposPendentesRevisao: (state: IFormErrorsState) => state.camposPendentesRevisao,
  getTotalErrors: (state: IFormErrorsState) => Object.values(state.formsErrors).reduce((total, errors) => total + errors.length, 0),
};

const mutations: MutationTree<IFormErrorsState>= {
  setFormErrors(state: IFormErrorsState, { formName, errors }: { formName: keyof IFormErrors; errors: IErrorField<object>[] }) {
    state.formsErrors[formName] = errors;
  },
  clearFormErrors(state: IFormErrorsState, formName: keyof IFormErrors) {
    state.formsErrors[formName] = [];
  },
  setCampoRevisado(state: IFormErrorsState, revisao: { formName: keyof IFormErrors; campo: string }) {
    const { formName, campo } = revisao;
  
    const index = state.formsErrors[formName].findIndex((error) => error.campo === campo);
  
    if (index !== -1) {
      state.formsErrors[formName].splice(index, 1);
    }
  },
  setCamposPendentesRevisao(state: IFormErrorsState, status: StatusCampos) {
    state.camposPendentesRevisao = status;
  },
};

const actions: ActionTree<any, RootState> = {
  updateFormErrors(
    { commit },
    { formName, errors }: { formName: keyof IFormErrors; errors: IErrorField<object>[] }
  ) {
    commit('setFormErrors', { formName, errors });
  },
  clearFormErrors({ commit }, formName: keyof IFormErrors) {
    commit('clearFormErrors', formName);
  },
  revisarCampo({ commit, getters }, revisao: { formName: keyof IFormErrors; campo: string }) {
    commit('setCampoRevisado', revisao);

    const totalErros = getters.getTotalErrors;

    if (totalErros > 0) {
      commit('setCamposPendentesRevisao', StatusCampos.PENDENTES);
    } else {
      commit('setCamposPendentesRevisao', StatusCampos.REVISADOS);
    }
  },
  async validarCampos({ commit }) {
    try {
      // const response = await this.$api.get(`/cpr-data/step/${step}`);
      const response = {
        success: false,
        data: {
          dadosCpr: [] ,
          condicaoEntrega: [
            // {
            //   campo: 'dataFinalEntrega',
            //   mensagem: 'A data final deve ser igual a data de vencimento dessa CPR.'
            // }
          ],
          dadosFinanceiros: [      
            // {
            //  campo: 'valorTotalCpr',
            //  mensagem: 'O valor total de pagamento não pode ser menor que a somatória das parcelas'
            // }
          ],
          formacoesLavoura: []
        } as IFormErrors
      }
  
      if (!response.success) {
        Object.entries(response.data).forEach(([formName, fields]) => {
          const errors = fields.map((field) => ({ campo: field.campo, mensagem: field.mensagem }));
          commit('setFormErrors', { formName, errors });
        });
        commit('setCamposPendentesRevisao', StatusCampos.PENDENTES);
      } else {
        Object.keys(response.data).forEach((formName) => {
          commit('clearFormErrors', formName);
        });
        commit('setCamposPendentesRevisao', StatusCampos.NAO_SE_APLICA);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do step:', error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
