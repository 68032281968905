import moment from 'moment'; 

/** @todo Remover uso de moment */ 
export const dataFormatada = (value?: string | Date) => {
  return !value ?  '' : moment(value).format('L');
}

/**
 * @param value precisa estar no formato YYYY-MM-DDT00:00:00
 * @return 12 de fevereiro de 2022
 */
export const dataFormatadaParaDocumento = (data: string | Date): string => {
  if (!data) return '';
  
  const dataObj = typeof data === 'string' ? new Date(data) : data;

  return dataObj.toLocaleDateString('pt-BR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};
/**
 * @param value precisa estar no formato YYYY-MM-DD
 * @return DD/MM/YYYY às HH:MM
 */
export const dataHoraformatada = (dataString:Date | string) => {
  if(!dataString) return '--/--/--- às --:--';
  const data = new Date(dataString);
  
  const dia = adicionarZeroEsquerda(data.getDate());
  const mes = adicionarZeroEsquerda(data.getMonth() + 1);
  const ano = data.getFullYear();
  
  const hora = adicionarZeroEsquerda(data.getHours());
  const minutos = adicionarZeroEsquerda(data.getMinutes());
  
  const dataFormatada = `${dia}/${mes}/${ano}`;
  const horaFormatada = `${hora}:${minutos}`;
  
  return `${dataFormatada} às ${horaFormatada}`;
}

const adicionarZeroEsquerda = (numero: number) => {
  return numero < 10 ? `0${numero}` : numero;
}

/** @param data 18/07/2024 às 15:05 */
export const convertStringToDate = (data:string) => {
  // Substitui " às " por um espaço e divide a string em data e hora
  const [dataPart, horaPart] = data.replace(' às ', ' ').split(' ');
  
  // Divide a data e a hora em suas partes
  const [dia, mes, ano] = dataPart.split('/').map(Number);
  const [hora, minuto] = horaPart.split(':').map(Number);
  
  // Cria o objeto Date
  const dataConvertida = new Date(ano, mes - 1, dia, hora, minuto);
  return dataConvertida
}

