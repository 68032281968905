import Vue from 'vue';
import CprFormInput from '~/components/cpr-form/cpr-form-input.vue';
import CprTransition from '~/components/cpr-transition.vue';
import CprFormSection from '~/components/cpr-form/cpr-form-section.vue';
import CprRadioButton from '@/components/cpr-radio-button/cpr-radio-button.vue';
const CprCheckbox =  ()=> import('@/components/cpr-checkbox.vue');
const CprButtonGroup =  ()=> import('@/components/cpr-button/cpr-button-group/index.vue');
const CprDivider =  ()=> import('@/components/cpr-divider/index.vue');

// Registrar o componente globalmente
Vue.component('cpr-form-input', CprFormInput);
Vue.component('cpr-form-section', CprFormSection);
Vue.component('cpr-radio-button', CprRadioButton);
Vue.component('cpr-transition', CprTransition);
Vue.component('cpr-checkbox', CprCheckbox);
Vue.component('cpr-button-group', CprButtonGroup);
Vue.component('cpr-divider', CprDivider);
