<template>
  <div class="container-fazendas">
    <div class="container-fazendas__head">
      <RouterLink to="/lavouras" class="p1">
        <i class="el-icon-arrow-left" />
        Lavouras
      </RouterLink>

      <h2>{{fazendaId ? 'Editar'  : 'Nova'}} Lavoura</h2>
    </div>
    <div class="container-fazendas__body">
      <ul class="container-fazendas__steps">
        <RouterLink
          class="container-fazendas__steps-item p1"
          v-for="item in steps"
          :key="item.path"
          :to="`#${item.path}`"
        >{{item.label}}</RouterLink>
      </ul>

      <cpr-form
        class="container-fazendas__form"
        :formModel="formFazenda"
        :submit="submitForm"
        :cancel="back"
      >
          <cpr-label label="Dados Gerais" id="dadosGerais" />
          <el-form-item prop="nomeFazenda" label="Nome da Fazenda"
            :rules="$validationForm(['required', { between: { min: 3, max: 35 } }])"
          >
            <cpr-input 
              v-model="formFazenda.nomeFazenda"
              maxlength="35"  
            />
          </el-form-item>
          <el-form-item prop="descricao" label="Descrição"
            :rules="$validationForm([{ max: 100 }])"
          >
            <cpr-input 
              v-model="formFazenda.descricao"
              maxlength="100"  
            />
          </el-form-item>
          <div class="is-multi">
            <el-form-item prop="areaTotal" label="Área total(ha)"
              :rules="$validationForm(['required', { max: 10 }])"
            >
              <cpr-input
                mask="numero"
                :precision="4"
                maxlength="10"
                v-model="formFazenda.areaTotal"
                placeholder="Defina a área em hectares"
              />
            </el-form-item>

            <el-form-item 
              prop="areaReservaLegal" 
              label="Reserva legal(ha)"
              :rules="$validationForm([{ max: 10 }])"
            >
              <cpr-input
                mask="numero"
                :precision="4"
                v-model="formFazenda.areaReservaLegal"
                maxlength="10"
                placeholder="Defina a reserva legal em hectares"
              />
            </el-form-item>
          </div>
          <div class="is-multi">
            <el-form-item prop="estadoIdComarca" label="Estado Comarca"
              :rules="$validationForm(['numberRequired'])"
            >
              <cpr-input
                v-model="formFazenda.estadoIdComarca"
                isSelect
                filterable
                clearable
                @input="(ufId) => listarMunicipiosComarca(ufId)"
                @change="clearProperty('municipioIdComarca')"
              >
                <el-option
                  v-for="item in listEstado"
                  :key="item.estadoId"
                  :label="item.nome"
                  :value="item.estadoId"
                >
                </el-option>
              </cpr-input>
            </el-form-item>
            <el-form-item prop="municipioIdComarca" label="Município Comarca"
              :rules="$validationForm(['numberRequired'])"
            >
              <cpr-input
                v-model="formFazenda.municipioIdComarca"
                isSelect
                filterable
                clearable
              >
                <el-option
                  v-for="item in listMunicipioComarca"
                  :key="item.municipioId"
                  :label="item.nome"
                  :value="item.municipioId"
                >
                </el-option>
              </cpr-input>
            </el-form-item>
          </div>

          <div class="is-multi">
            <el-form-item prop="numeroCar" label="Nº C.A.R"
              :rules="$validationForm([{ max: 50 }])"
            >
              <cpr-input 
                v-model="formFazenda.numeroCar"         
                maxlength="50"
              />
            </el-form-item>
            <el-form-item prop="numeroMatricula" label="Nº Matrícula" :rules="$validationForm(['required', { max: 6 }])">
              <cpr-input 
                v-model="formFazenda.numeroMatricula"
                maxlength="6"
              />
            </el-form-item>
          </div>


          <cpr-label label="Localização" id="localizacao" style="margin-top:30px;"/>

          <div class="is-multi">
            <el-form-item prop="estadoId" label="Estado"
              :rules="$validationForm(['numberRequired'])"
            >
              <cpr-input
                v-model="formFazenda.estadoId"
                isSelect
                filterable
                clearable
                @input="(ufId) => listarMunicipios(ufId)"
                @change="clearProperty('municipioId')"
              >
                <el-option
                  v-for="item in listEstado"
                  :key="item.estadoId"
                  :label="item.nome"
                  :value="item.estadoId"
                >
                </el-option>
              </cpr-input>
            </el-form-item>

            <el-form-item prop="municipioId" label="Município"
            :rules="$validationForm(['numberRequired'])"
            >
              <cpr-input
                v-model="formFazenda.municipioId"
                filterable
                clearable
                isSelect
              >
                <el-option
                  v-for="item in listMunicipio"
                  :key="item.municipioId"
                  :label="item.nome"
                  :value="item.municipioId"
                >
                </el-option>
              </cpr-input>
            </el-form-item>
          </div>
          <div class="is-multi">
            <el-form-item prop="latitude" label="Latitude"
              :rules="$validationForm([
                { 
                  numberBetween: { max: 90, min:-90},
                }, 
              ])"
            >
              <cpr-input
                v-model="formFazenda.latitude"
                mask="numero"
                allowNegative
                :precision="6"
                :maxlength="10"
                placeholder="-90,0000° à 90,0000°"
              />
            </el-form-item>
            <el-form-item prop="longitude" label="Longitude"
              :rules="$validationForm([
                { 
                  numberBetween: { max: 180, min:-180},
                }, 
              ])"
            >
              <cpr-input
                v-model="formFazenda.longitude"
                mask="numero"
                :precision="6"
                allowNegative
                :maxlength="11"
                placeholder="-180,0000° à 180,0000°"
              />
            </el-form-item>
          </div>

          <cpr-label
            label="Proprietários / Arrendatários"
            id="proprietarios"
            style="margin-bottom: 15px"
          />
          <el-button @click="toggleModalProprietario" icon="el-icon-plus"
            >Novo Proprietário
          </el-button>
          
          <div class="table" style="margin: 1rem 0" v-if="hasProprietarios">
            <el-table
              header-cell-class-name="team__header"
              :data="proprietariosList"
            >
              <el-table-column
                v-for="item in headTableProprietarios"
                :key="item.label"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
              />

              <el-table-column
                :prop="'tipoProprietario'"
                :width="120"
                :label="'Tipo'"
              >
                <template v-slot="{ row }">
                  <span> {{TipoProprietarioDictionary[row.tipoProprietario]}}</span>
                </template>
              </el-table-column>

              <el-table-column 
                label="Ações"
                align="center"
                prop="acoes"
                width="150" 
              >
                <template v-slot="scope">
                  <el-button-group>
                    <el-button size="mini" icon="el-icon-edit" @click="editProprietario(scope, scope.$index)"/>
                    <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteProprietario(scope.row, scope.$index)"/>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
      </cpr-form>
      <modal-form-proprietario
        :data="getFormProprietario"
        :index="proprietarioIndex"
        :showModal="handleModalProprietario"
        :toggleModalProprietario="toggleModalProprietario"
        @submit="addProprietario"
        @cancel="clearFormProprietario"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CprForm from '../../../components/cpr-form/cpr-form.vue';
import Input from '../../../components/Input.vue';
import Label from '../../../components/Label.vue';
import { IEstadoModel, IMunicipioModel } from '~/models/locais';
import { IFazendaModel, IProprietarioModel } from '~/models/fazenda';
import ServiceFormacaoLavoura from '../../../services/FormacaoLavoura';
import ServiceFazenda from '../../../services/Fazenda';
import ModalFormProprietario from './ModalFormProprietario.vue';
import { isEmpty } from 'lodash';
import { retirarFormatacaoNumero } from '~/utils/formats';
import { TipoProprietarioDictionary } from '~/Enums/tipoProprietario ';

@Component({
  components: {
    CprForm,
    ModalFormProprietario,
    'cpr-input': Input,
    'cpr-label': Label,
  },
})
export default class FormFazenda extends Vue {
  formFazenda:IFazendaModel = {
    nomeFazenda: '',
    numeroMatricula: '',
    numeroLivroRegistro: '',
    numeroCar: '',
    areaTotal: 0,
    areaReservaLegal: 0,
    estadoId: '',
    estadoIdComarca: '',
    municipioId: '',
    municipioIdComarca: '',
    proprietarios: []
  };
  fazendaId!:string;
  formProprietario = {} as IProprietarioModel;
  listEstado: Array<IEstadoModel> = [];
  listMunicipio: Array<IMunicipioModel> = [];
  listMunicipioComarca: Array<IMunicipioModel> = [];
  openModalProprietario = false;
  proprietarioIndex:number | null = null;

  get steps() {
    return [
      {
        path: 'dadosGerais',
        label: 'Dados Gerais',
      },
      {
        path: 'localizacao',
        label: 'Localização',
      },
      {
        path: 'proprietarios',
        label: 'Proprietários',
      },
    ];
  }

  get TipoProprietarioDictionary() {
    return TipoProprietarioDictionary
  }

  get headTableProprietarios(){
    return [
      {
        prop: 'nome',
        label: 'Nome',
      },      
      {
        prop: 'cpf',
        label: 'CPF',
        width: '200',
      }
    ];
  }

  get handleModalProprietario(){
    return this.openModalProprietario
  }

  get hasProprietarios(){
    return !isEmpty(this.formFazenda.proprietarios);
  }

  get getFormProprietario(){
    return this.formProprietario;
  }

  get proprietariosList(){
    return this.formFazenda.proprietarios
  }

  created(){
    this.fazendaId = this.$route.params.id;
  }

  async mounted() {
    this.listEstado = await ServiceFormacaoLavoura.obterEstados();
    if(this.fazendaId){
      try {
        const { data } = (await ServiceFazenda.getById(this.fazendaId))
        this.formFazenda = data
      } catch (error) {
        this.$showMessagesErrors(error);
      }
    }
  }

  toggleModalProprietario() {
    this.proprietarioIndex = null;
    this.openModalProprietario = !this.openModalProprietario;
  }

  async listarMunicipios(ufId: number) {
    this.listMunicipio = [];
    if (!ufId) return;
    this.listMunicipio = await ServiceFormacaoLavoura.obterMuniciosPorUfId(
      ufId
    );
  }

  async listarMunicipiosComarca(ufId: number) {
    this.listMunicipioComarca = [];
    if (!ufId) return;
    this.listMunicipioComarca =
      await ServiceFormacaoLavoura.obterMuniciosPorUfId(ufId);
  }

  clearFormProprietario() {
    this.formProprietario = {} as IProprietarioModel;
  }

  addProprietario(item: { index:number } & IProprietarioModel ){
    //VERIFICAR SE TEM INDEX PRA ADD OR UPDATE
    const index = item.index
    const proprietario =  { ...item, index: undefined };

    if(typeof index === 'number') {
      this.formFazenda.proprietarios.splice(index, 1);
    }
    this.formFazenda.proprietarios?.push(proprietario);

    this.clearFormProprietario();
    this.$showNotify('success', 
      'Para registrá-lo na fazenda, conclua o formulário', 
      `Proprietário ${index ? 'editado' : 'incluído'} com sucesso!`
    )
  }

  editProprietario(item, index){
    this.formProprietario = {
      ...item.row,
      fazendaId: this.fazendaId,
    }
    this.toggleModalProprietario();
    this.proprietarioIndex = index;
  }

  async deleteProprietario(item:IProprietarioModel, index:number) {
    if (item?.proprietarioId) {
      this.$cprAlert({
        type: 'delete',
        title: 'Excluindo Proprietário!',
        textInfo: `Você está excluindo o proprietário <b>${item.nome}</b>. Caso confirme, os dados serão ` +
          '<b>excluídos</b> e <b>não poderão ser recuperados</b>. O que você deseja fazer?',
        namePositiveAction: 'Manter Proprietário',
        nameNegativeAction: 'Excluir Proprietário',
        negativeAction: async () => {
          try {
            await ServiceFazenda.deleteProprietario(this.fazendaId, item.proprietarioId);
            this.$showNotify('success', `Proprietário ${item.nome} foi removido com sucesso!`)
            this.formFazenda.proprietarios.splice(index, 1);
          } catch (error) {
            this.$showMessagesErrors(error)
          }
        }
      })
    } else {
      this.formFazenda.proprietarios.splice(index, 1);
    }
  }

  clearProperty(key: string) {
    if (this.formFazenda[key]) {
      this.formFazenda[key] = '';
    }
  }

  async submitForm(){
    try {
      const payload:IFazendaModel = {
        ...this.formFazenda,
        areaTotal: retirarFormatacaoNumero(this.formFazenda?.areaTotal?.toString(), 4),
        areaReservaLegal: retirarFormatacaoNumero(this.formFazenda?.areaReservaLegal?.toString() || '0', 4),
      };
      await ServiceFazenda.save(payload, this.fazendaId);
      this.$showNotify('success', `Fazenda ${this.fazendaId ? 'editada' : 'cadastrada'} com sucesso!`);
      this.back();
    } catch (error) {
      this.$showMessagesErrors(error);
    }
  }

  back() {
    this.$router.push('/lavouras');
  }

}
</script>

<style lang="scss" scoped>
.container {
  &-fazendas {
    &__head {
      top: 112px;
      display: flex;
      flex-direction: column;
      position: fixed;
      align-items: center;

      a {
        color: $--color-gray-6;
        display: flex;
        align-items: center;
      }

      h2 {
        color: $--color-gray-7;
        margin-top: 4px;
      }
    }

    &__body {
      display: flex;
      margin-top: 36px;
    }

    &__steps {
      top: 212px;
      width: fit-content;
      padding: 32px;
      position: fixed;
      box-shadow: $--box-shadow-base;
      border-radius: 4px;
      background-color: $--color-gray-1;

      &-item {
        color: $--color-gray-6;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.4s ease-in-out;

        &:hover {
          color: $--color-gray-7;
        }

        &:not(:first-child) {
          margin-top: 12px;
        }

        &.router-link-active {
          color: $--color-primary;

          &::before {
            background-color: $--color-primary;
          }
        }
      }
    }

    &__form {
      width: 100%;
      margin: 100px 0 0 280px;

    }
    & .is-multi {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      & > :nth-child(n) {
        width: 49%;
      }
    }

    .table__actions{
      display: flex;
      justify-content: center;
      & > :nth-child(n) {
        font-size: 18px;
      }

      & > :nth-child(1) {
        padding-right: 6px;
      }
    }
  }
}
.margin-bottom {
  margin-bottom: 0px;
}
</style>