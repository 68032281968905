/**  
 * @deprecated
 * @alias TipoPessoaEnum
*/
export enum TipoPessoa {
  PESSOA_FISICA = 'PF',
  PESSOA_JURIDICA = 'PJ',
}

export const TipoPessoaDictionary = {
  [TipoPessoa.PESSOA_FISICA]: 'Pessoa física',
  [TipoPessoa.PESSOA_JURIDICA]: 'Pessoa jurídica',
}

/** @todo Padronizar para esse */
export enum TipoPessoaEnum {
  PESSOA_FISICA = 1,
  PESSOA_JURIDICA,
}

export const TipoPessoaEnumDictionary = {
  [TipoPessoaEnum.PESSOA_FISICA]: 'Pessoa física',
  [TipoPessoaEnum.PESSOA_JURIDICA]: 'Pessoa jurídica',
}