export const formatarCnpj = (value?: string) => {
  if(!value) return '';
  return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export const formatarCpf = (value?: string) => {
  if(!value) return '';
  return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export const formatarTelefone = (value: string) => {
  if(!value) return '';
  return value.replace(' ','').replace('\(','').replace('\)','').replace('-','');
}

/**
 * 
 * @param traco passar true caso queira incluir o traço
 * @returns 74000000 || 74000-000
 */
export const formatarCEP = (cep: string, traco?: boolean) => {
  if(!cep) return '';  
  return traco 
  ? cep.trim().replace(/^(\d{5})(\d{3})$/, '$1-$2') 
  : cep.trim().replace(/[^0-9]/g, '');
}

/**
 * 
 * @param precisao a precisão padrão é duas casas decimais, caso queira maior, passar o parâmetro.
 * @returns 0,00 | 0,0000
 */
export const formatarNumero = (numero:number, precisao = 2) => {
  if(typeof numero != 'number') numero = 0
  numero = parseFloat(numero.toString());
  const opcoes = { 
    minimumFractionDigits: precisao, 
    maximumFractionDigits: precisao 
  };

  const numeroFormatado = numero.toLocaleString('pt-BR', opcoes);

  return numeroFormatado;
}

export const retirarFormatacaoNumero = (numero?:string, p = 2) => {
  if(!numero) numero = '0,00'
  if(typeof numero === 'number') numero = formatarNumero(numero)
  const precisaoAuto = numero?.split(',')[1]
  const precisao = (p > 2 || !precisaoAuto) ? p : precisaoAuto.length
  
  return parseFloat(
    parseFloat(numero.replace(/\./g, '')
    .replace(',', '.'))
    .toFixed(precisao)
  );
}

export const formatValue = (value: number) => {
  return value
    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    .replace(/\s/g, ' ');
};


export const functions = {
  formatarCnpj,
  formatarCpf,
  formatarTelefone,
  formatarCEP,
  formatarNumero,
  retirarFormatacaoNumero,
  formatValue
}

export default functions
