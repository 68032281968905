<template>
  <el-form-item 
    :prop="prop" 
    :label-suffix="prop" 
    :rules="$validationForm(rulesValidationsProp)"
    :class="{'is-warning': error}"
  >
    <template #label> 
      {{label}}
      <el-tooltip v-if="info" effect="dark" :content="info" placement="top">
        <i class="el-icon-info" style="margin-left: 12px;"/>
      </el-tooltip>
    </template>

    <cpr-input
      v-model="valor"
      v-bind="$attrs"
      :placeholder="placeholder"
      :id="prop"
      :loading="loading"
      :prefixIcon="prefixIcon"
      :suffixIcon="suffixIcon"
      @change="handleChange"
    >
    <slot/>
    </cpr-input>
    <p class="label-warning">{{ error ? error.mensagem : ''}}</p>
  </el-form-item>
</template>

<script lang="ts">
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import CprInput from '../Input.vue';
import { ValidationRule } from '~/utils/validationForm';
import { mapGetters } from 'vuex';
import { IErrorField, IFormErrors } from '~/types/store/form-errors';

/** @todo utilizar esse componente para inputs de formulário */
@Component({
  name:'cpr-form-input',
  components: {
    'cpr-input': CprInput,
  },
  computed: {
    ...mapGetters( {
      getFormErrors: 'formErrors/getFormErrors',
    })
  },
  inject: ['formName'],
})
export default class CprFormInput extends Vue {
  @Prop({default:''}) label!:string;
  @Prop({default:'', required: true}) prop!:string;
  @Prop({default:''}) prefixIcon!:string;
  @Prop({default:''}) suffixIcon!:string;
  @Prop({default:''}) info!:string;
  @Prop({default: false }) loading!:boolean;
  @Prop({default:''}) placeholder!:string;
  /** @see ValidationRule */
  @Prop({default:()=>[]}) rules!:ValidationRule[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @VModel() valor!:any;

  formName?:()=> string
  getFormErrors?: IFormErrors 

  get rulesValidationsProp():ValidationRule[] {
    if(this.error) {
      return [...this.rules, { validate: this.addRuleError, trigger: ['blur'] }]
    }
    return [...this.rules]
  }

  get error () {
    const key = this.formName ? this.formName() : ''
    if(!key) return undefined
    const listErrors:IErrorField<object>[] = this.getFormErrors ? this.getFormErrors[key] : []
    return listErrors?.find( e=> e.campo === this.prop)
  }

  addRuleError() {
    return {
      message: this.error?.mensagem || '',
      success: !Boolean(this.error?.campo)
    }
  }

  revisarCampo () {
    const key = this.formName ? this.formName() : ''
    if(!this.error || !key) return false
    this.$store.dispatch('formErrors/revisarCampo', { formName: key, campo: this.prop })
  }

  handleChange(value) {
    this.$emit('change', value)
    if(value !== undefined && value !== null && value !== '') this.revisarCampo()
  }
  
}
</script>

<style lang="scss">

.label-warning {
  font-size: 12px;
  color: get-color(alert, low-light);
  font-weight: 600;
}

.el-form-item {
  &.is-warning {
    .el-form-item__error {
      display: none;
    }

    .el-input__inner,
    .el-input__inner:focus,
    .el-textarea__inner,
    .el-textarea__inner:focus {
      border-color: get-color(alert, low-light);
    }
  }
}

.el-message-box__input {
  &.is-warning {
    input.invalid,
    input.invalid:focus {
      border-color: get-color(alert, low-light);
    }
  }
}


/** @todo retirar ao padronizar as cores da plataforma */
.el-select {
  &-dropdown  {
    background-color: get-color(neutral, ultra-light);

    &__item {
      color: get-color(neutral, low-dark);

      &.selected {
        color: get-color(primary, low-medium);
        background-color: get-color(primary, high-light);

      }

      &:hover{
        background-color: get-color(neutral, high-light);
      }
    }
  }
}

.el-tooltip {
  position: relative;
  z-index: 2;
  max-width: 350px;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: get-color(primary, low-medium);
  background-color: get-color(primary, high-light);
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background-color: get-color(neutral, high-light);
}

.el-tag {
  border-radius: 8px;

  &.el-tag--info {
    background-color: get-color(primary, high-light);
    border: none;
    font-size: 13px;
    font-weight: 600;
    color: get-color(primary, low-medium);

    .el-tag__close{
      color: get-color(primary, low-light);
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;

      &:hover {
        color: #FFF;
        background-color: get-color(primary, low-medium);
      }
    }
  }
}

.el-input {
  &__inner{
    background-color: get-color(neutral, ultra-light);
    border: 1px solid get-color(neutral);

    &:focus {
      outline: none;
      border-color: get-color(primary, low-light);
    }
  }

  &.is-disabled {
    .el-input__inner {
      background-color:get-color(neutral, high-medium) ;
    }
  }
}

.input__target .el-input__inner, .input__target .el-input-number__inner {
  color: get-color(neutral, low-dark) !important;
}

.el-select:hover .el-input__inner,
.el-range-editor.is-active, 
.el-range-editor.is-active:hover, 
.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus {
  border: 1.5px solid get-color(neutral, low-medium);
}

@media screen and (max-width: 420px) {
  .el-select-dropdown__item {
    max-width: 98vw;
  }
}
</style>